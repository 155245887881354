import {
    Input,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Stack,
    StackDivider,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { Frame } from "../../components/wrappers/Frame"

function NumberSliderDemo() {
    const [value, SetValue] = useState(42)

    function handleChange(value: number) {
        SetValue(value)
    }

    return (
        <Frame>
            <Stack
                direction={["column", null, "row"]}
                spacing={8}
                borderColor="inherit"
                divider={<StackDivider />}
            >
                <Input
                    value={value}
                    onChange={e =>
                        handleChange(Number.parseInt(e.target.value))
                    }
                />
                <Slider
                    min={0}
                    max={100}
                    value={value}
                    onChange={handleChange}
                    focusThumbOnChange={false}
                >
                    <SliderTrack>
                        <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                </Slider>
            </Stack>
        </Frame>
    )
}

export { NumberSliderDemo }
