import React from 'react'
import { Box } from "@chakra-ui/react";

function Frame({children}: React.PropsWithChildren<{}>) {
    return (
        <Box
            borderWidth="medium"
            borderColor="gray.300"
            borderRadius="md"
            p={4}
            _dark={{
                borderColor: "gray.600",
            }}
        >
            {children}
        </Box>
    )
}

export { Frame }